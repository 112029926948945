.edit-show-projects-page__grid {
  display: grid;
  width: 100%;
  max-width: 1248px;
  grid-gap: 16px;
  grid-template-areas:
    /* "no-projects-selector" */ "projects-configurator";
  margin: auto;
}
#projects-configurator {
  grid-area: projects-configurator;
}
/* #no-projects-selector {
  grid-area: no-projects-selector;
} */
