.multiplex-current-dashboard-selector--no-projects {
    opacity: 0.5;
    pointer-events: none;
}

.multiplex-current-dashboard__list {
    background-color: whitesmoke;
    border-radius: 8px;
    margin-top: 8px;
    padding: 8px;
    min-height: 320px;
    height: calc(100vh - 320px);
    /* NOTE adjust height */
    overflow-y: auto;
}
.multiplex-current-dashboard-selector__list__item {
    background-color: var(--color-light);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 8px;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-bottom: 8px;
    border: 2px solid transparent;
}
.multiplex-current-dashboard-selector__list__item--active {
    border-color: #2ca795;
}

.multiplex-current-dashboard-selector__list__item__logo {
    height: 44px;
    min-width: 44px;
    background-repeat: no-repeat;
    background-size: calc(100% - 8px);
    border: 1px solid grey;
    background-position: 50%;
    border-radius: 8px;
    margin-right: 16px;
    overflow: hidden;
}
.multiplex-current-dashboard-selector__list__item__logo__counter-link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 100ms ease-in-out;
}
.multiplex-current-dashboard-selector__list__item__logo__counter-link:hover {
    background-color: rgba(255, 255, 255, 0.88);
    opacity: 1;
}
.multiplex-current-dashboard-selector__list__item__infos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}
.multiplex-current-dashboard-selector__list__item__infos__name {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 8px;
}
.multiplex-current-dashboard-selector__list__item__infos__goal {
    text-align: right;
    font-weight: bold;
    font-size: 14px;
}
.multiplex-current-dashboard-selector__list__item__infos__goal > span {
    color: #848aae;
}
.multiplex-current-dashboard-selector__list__item__infos__goal > span:last-of-type {
    color: #2ca795;
}
.multiplex-current-dashboard-selector__list__item__infos__goal--reached {
    color: #2ca795 !important;
}

.multiplex-current-dashboard-project-selector__list__item__infos__name span {
    font-weight: bold;
    text-transform: capitalize;
}
