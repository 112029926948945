.menu-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
}
.menu-item--top-separator {
  border-top: 1px solid grey;
  padding-top: 16px;
}
.menu-item__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  margin-right: 8px;
}
.menu-item__text {
  color: var(--color-light);
}
.menu-item--muted .menu-item__icon,
.menu-item--muted .menu-item__text {
  opacity: 0.33;
  transition: opacity 200ms ease;
}
.menu-item--muted:hover .menu-item__icon,
.menu-item--muted:hover .menu-item__text {
  opacity: 1;
}

.menu-item__condensed .menu-item__icon {
  border: 1px solid var(--color-light);
  margin-right: 0px;
}
.menu-item__condensed .menu-item__text {
  display: none;
  position: absolute;
}
.menu-item__condensed:hover .menu-item__icon {
  background-color: rgba(255, 255, 255, 0.05);
}
.menu-item__condensed:hover .menu-item__text {
  display: block;
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 8px;
  font-size: 14px;
  border: 1px solid grey;
  margin-left: 56px;
  white-space: nowrap;
  overflow: hidden;
}
