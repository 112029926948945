.show-card-infos__place-container {
  display: block;
  font-weight: bold;
  opacity: 0.75;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 14px;
}
.show-card-infos__name-container {
  font-size: 22px;
  font-weight: bold;
  color: white;
  opacity: 0.9;
  margin: 4px 0 8px 0;
}
.show-card-infos__subtitle-and-indicator {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.show-card-infos__subtitle {
  font-weight: bold;
  opacity: 0.75;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 14px;
}
