.show-card-calendar {
  background-color: white;
  width: 56px;
  height: 64px;
  border-radius: 8px;

  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.show-card-calendar__day-container {
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  opacity: 0.8;
}
.show-card-calendar__month-container {
  font-size: 14px;
  line-height: 16px;
  opacity: 0.8;
}
.show-card-calendar__year-container {
  font-size: 12px;
  line-height: 14px;
  opacity: 0.5;
}
