.multiplex-infos-form {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr;
}

.multiplex-shows__event {
    padding: 1rem 0;
}
.multiplex-shows__event:not(:last-child) {
    border-bottom: 1px solid rgba(190, 190, 190, 0.5);
}

.multiplex-shows__event__remove {
    margin-right: 1rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.multiplex-shows__event__link {
    cursor: pointer;
}

.multiplex-color-picker {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}