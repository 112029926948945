.registrations-custom-configurator__new-action {
  margin-top: 16px;
  width: 100%;
}
.registrations-custom-configurator__configurator {
  background-color: whitesmoke;
  margin-top: 16px;
  padding: 8px;
  height: calc(100vh - 245px);
  border-radius: 8px;
  overflow-y: auto;
  min-height: 250px;
}
.registrations-custom-configurator__configurator__item {
  background-color: var(--color-light);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
}
.registrations-custom-configurator__configurator__item__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
}
.registrations-custom-configurator__configurator__item__header__input {
  width: 100%;
  margin-right: 16px;
}
.registrations-custom-configurator__configurator__item__header__required {
  margin-right: 16px;
}
.registrations-custom-configurator__configurator__item__header__required
  > span {
  font-size: 12px;
  text-align: center;
  width: 50px;
  display: block;
  margin-bottom: 2px;
}
.registrations-custom-configurator__configurator__item__header__delete {
  align-self: flex-start;
  cursor: pointer;
  transition: 200ms all ease;
}
.registrations-custom-configurator__configurator__item__header__delete:hover {
  filter: brightness(1.3);
}
.registrations-custom-configurator__configurator__item__type-selector {
  background-color: rgb(230, 230, 230);
  padding: 2px;
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  border: 1px solid #e4e4e4;
}
.registrations-custom-configurator__configurator__item__type-selector__selector {
  width: 33.3%;
  text-align: center;
  padding: 2px;
  cursor: pointer;
}
.registrations-custom-configurator__configurator__item__type-selector__selector--active {
  background-color: white;
  border-radius: 16px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}
