.login-page {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-page__login-form {
  min-width: 300px;
  margin: 32px 0 32px 0;
}
.login-page__login-form div:first-of-type {
  margin-bottom: 16px;
}
.login-page__login-form div:last-of-type {
  margin-bottom: 16px;
}
.login-page__login-form__password-reset {
  font-size: 14px;
  color: cornflowerblue;
}
.login-page__login-form__password-reset:hover {
  cursor: pointer;
  text-decoration: underline;
}
