.multiplex-manager__grid {
    display: grid;
    width: 100%;
    max-width: 1248px;
    grid-gap: 16px;
    grid-template-areas:
            "multiplex-manager__infos"
            "multiplex-manager__count"
            "live-current-project-selector"
            "multiplex-manager__dashboard-list"
            "multiplex-manager__event-list";
    margin: auto;
}

#multiplex-manager__infos {
    grid-area: multiplex-manager__infos;
}

#multiplex-manager__count {
    grid-area: multiplex-manager__count;
}

#multiplex-manager__dashboard-list {
    grid-area: multiplex-manager__dashboard-list;
}

#multiplex-manager__show-list {
    grid-area: multiplex-manager__event-list;
}

.multiplex-manager__count {
    display: flex;
    justify-content: space-between;
}

.multiplex-manager__infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.multiplex-manager__count span,
.multiplex-manager__infos span{
    color: #2ca795;
    font-weight: bold;
    font-size: 16px;
}

.multiplex-manager__toggle {
    display: flex;
    align-items: center;
}

.multiplex-manager__toggle-label {
  margin: 0 0.5rem;
}

.multiplex-manager__dashboard-list__inner-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: whitesmoke;
    border-radius: 8px;
    margin-top: 8px;
    padding: 8px;
}

.multiplex-manager__dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.multiplex-manager__dashboard__title {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 8px;
}

.multiplex-manager__dashboard__progress {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 8px;
    text-align: end;
}

.multiplex-manager__dashboard__count {
    color: #848aae;
}

.multiplex-manager__dashboard__goal {
    color: #2ca795;
}

.multiplex-manager__show {
    padding: 0.5rem 1rem;
}

.multiplex-manager__show__info span {
    font-weight: bold;
}

@media screen and (min-width: 1024px) {
    .multiplex-manager__grid {
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas:
            "multiplex-manager__infos multiplex-manager__infos multiplex-manager__infos"
            "multiplex-manager__count multiplex-manager__count multiplex-manager__count"
            "live-current-project-selector multiplex-manager__dashboard-list multiplex-manager__event-list";
    }
}
