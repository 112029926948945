.live-manager-page__grid {
  display: grid;
  width: 100%;
  max-width: 1248px;
  grid-gap: 16px;
  grid-template-areas:
    "live-stats"
    "live-current-project-selector"
    "live-pledges-monitor"
    "live-chat-monitor"
    "live-closing-action";
  margin: auto;
}
.live-manager-page__grid--no-projects {
  grid-template-areas:
    "live-stats"
    "live-pledges-monitor"
    "live-chat-monitor"
    "live-closing-action";
}
#live-stats {
  grid-area: live-stats;
}
#live-current-project-selector {
  grid-area: live-current-project-selector;
}
#live-pledges-monitor {
  grid-area: live-pledges-monitor;
}
#live-chat-monitor {
  grid-area: live-chat-monitor;
}
#live-closing-action {
  grid-area: live-closing-action;
}
@media screen and (min-width: 1024px) {
  .live-manager-page__grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "live-stats live-stats live-stats"
      "live-current-project-selector live-pledges-monitor live-chat-monitor"
      "live-closing-action live-closing-action live-closing-action";
  }
  .live-manager-page__grid--no-projects {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "live-stats live-stats"
      "live-pledges-monitor live-chat-monitor"
      "live-closing-action live-closing-action";
  }
}
