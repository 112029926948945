.base-card {
  content: "Card";
  background-color: var(--color-light);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 16px;
  border-radius: 8px;
}
.base-card--tight {
  padding: 8px;
}
