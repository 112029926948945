.show-card-infos-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  font-weight: bold;
  border: 1px solid #848aae;
  border-radius: 64px;
  font-size: 12px;
  padding: 1px 8px;
  min-width: 90px;
  margin-left: 8px;
}
.show-card-infos-indicator span {
  white-space: nowrap;
  color: white;
}
.show-card-infos-indicator__red-circle {
  background-color: red;
  border-radius: 64px;
  height: 10px;
  width: 10px;
  margin-right: 4px;
}
.show-card-infos-indicator__countdown {
  min-width: 4rem;
  text-align: center;
}
