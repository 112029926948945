.home-page__shows-grid {
  max-width: 1248px;
  margin: auto;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(1, 1);
  grid-gap: 16px;
}

.home-page__shows-grid__more-show {
  cursor: pointer;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #848aae;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.home-page__shows-grid__more-show span {
  color: #848aae;
}
.home-page__shows-grid__more-show:hover span {
  text-decoration: underline;
}
.home-page__shows-grid__more-show:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
@media screen and (min-width: 550px) {
  .home-page__shows-grid {
    grid-template-columns: repeat(2, calc((100% - 16px) / 2));
  }
}

@media screen and (min-width: 950px) {
  .home-page__shows-grid {
    grid-template-columns: repeat(3, calc((100% - 32px) / 3));
  }
}
@media screen and (min-width: 1248px) {
  .home-page__shows-grid {
    grid-template-columns: repeat(4, calc((100% - 48px) / 4));
  }
}
