.dashboards-configurator {
    height: calc(100vh - 128px);
}
.dashboards-configurator--editor {
    height: fit-content;
}
.dashboards-configurator__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.dashboards-configurator__configurator {
    background-color: whitesmoke;
    margin-top: 16px;
    padding: 8px;
    height: calc(100% - 46px);
    border-radius: 8px;
    overflow-y: auto;
}
.dashboards-configurator__configurator__item {
    background-color: var(--color-light);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 8px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: grab;
    margin-bottom: 8px;
}
.dashboards-configurator__configurator__item__heading {
    display: flex;
    align-items: center;
}

.dashboards-configurator__editor {
    margin-top: 16px;
}
.dashboards-configurator__editor__form__input,
.dashboards-configurator__editor__logo-editor {
    margin-bottom: 16px;
}
.dashboards-configurator__editor__form__actions {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
}

.dashboards-configurator__configurator__item__heading {
    display: flex;
    align-items: center;
}
.dashboards-configurator__configurator__item__heading__infos {
    display: flex;
    flex-direction: column;
}
.dashboards-configurator__configurator__item__heading__infos
> span:first-of-type {
    font-weight: bold;
    margin-bottom: 2px;
}
.dashboards-configurator__configurator__item__heading__infos
> span:first-of-type {
    font-weight: bold;
    margin-bottom: 2px;
}
.dashboards-configurator__configurator__item__heading__infos > span:last-of-type {
    font-size: 14px;
}
.dashboards-configurator__configurator__item__heading__infos
> span:last-of-type
> span {
    color: #2ca795;
    font-weight: bold;
}
.dashboards-configurator__configurator__item__heading__logo {
    height: 44px;
    width: 44px;
    background-repeat: no-repeat;
    background-size: calc(100% - 8px);
    border: 1px solid grey;
    background-position: 50%;
    border-radius: 8px;
    margin-right: 16px;
}
.dashboards-configurator__project {
    padding: 1rem 0;
}
.dashboards-configurator__project:not(:last-child) {
    border-bottom: 1px solid rgba(190, 190, 190, 0.5);
}
.dashboards-configurator__project__remove {
    margin-right: 1rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
}
.projects-configurator__configurator__item__actions {
  display: flex;
}