.pledges-presets-form__form__presets-activator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
}
.pledges-presets-form__form__presets-grid {
  display: grid;
  grid-template-areas:
    "first-pledge-preset second-pledge-preset"
    "third-pledge-preset fourth-pledge-preset";
  grid-gap: 16px;
}
