.registrations-preset-selector__toggle-labels {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  font-size: 12px;
  margin-top: 16px;
}
.registrations-preset-selector__toggle-labels__labels {
  width: 120px;
  display: flex;
  justify-content: space-between;
}
.registrations-preset-selector__toggle-labels span {
  width: 50%;
  max-width: 50px;
  text-align: center;
}
.registrations-preset-selector__toggle-labels span:first-of-type {
}
.registrations-preset-selector__form__toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.registrations-preset-selector__form__toggle__toggles {
  display: flex;
  justify-content: space-between;

  width: 120px;
}
