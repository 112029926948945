.multiplexes-page__multiplexes-grid {
    max-width: 1248px;
    margin: auto;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1);
    grid-gap: 16px;
}

.multiplexes-page__multiplexes-grid__more-multiplexes {
    cursor: pointer;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #848aae;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.multiplex-card {
    position: relative;
    width: 100%;
    background-size: cover;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 8px;
    cursor: pointer;
    background-position: center;
    background-color: #c8c8c8;
}
.multiplex-card__content {
    height: 300px;
    padding: 16px;
    background: linear-gradient(transparent, #00000060, #00000099, #000000f9);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
}
.multiplex-card__content__cal-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.multiplex-card__content__infos-container__title{
    font-size: 22px;
    font-weight: bold;
    color: white;
    opacity: 0.9;
    margin: 4px 0 8px 0;
}
.multiplex-card__content__infos-container__subtitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    opacity: 0.75;
    font-size: 14px;
    min-height: 17px;
}
.multiplexes-page__multiplexes-grid__more-multiplexes {
    cursor: pointer;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #848aae;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}
.multiplexes-page__multiplexes-grid__more-multiplexes span {
    color: #848aae;
}
.multiplexes-page__multiplexes-grid__more-multiplexes:hover span {
    text-decoration: underline;
}
.multiplexes-page__multiplexes-grid__more-multiplexes:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}


@media screen and (min-width: 550px) {
    .multiplexes-page__multiplexes-grid {
        grid-template-columns: repeat(2, calc((100% - 16px) / 2));
    }
}
@media screen and (min-width: 950px) {
    .multiplexes-page__multiplexes-grid {
        grid-template-columns: repeat(3, calc((100% - 32px) / 3));
    }
}
@media screen and (min-width: 1248px) {
    .multiplexes-page__multiplexes-grid {
        grid-template-columns: repeat(4, calc((100% - 48px) / 4));
    }
}