.base-input-color {
    width: 100%;
}

.base-input-color-flex {
    display: flex;
    justify-content: flex-start;
}


.base-input-color-before-input {
    overflow: hidden;
}

.base-input-color label {
    display: block;
    font-weight: 600;
    font-size: 14px;
    margin-right: 16px;
    margin-bottom: 8px;
}


.base-input-color-live-transparency {
    margin-left: 2em;
    height: 2em;
    border-radius: 50px;
}