.edit-multiplex-infos-page__grid {
    display: grid;
    width: 100%;
    max-width: 1248px;
    grid-gap: 16px;
    grid-template-areas:
    "multiplex-infos-form";
    margin: auto;
}

#multiplex-infos-form {
    grid-area: multiplex-infos-form;
}