.edit-show-pledges-page__grid {
  display: grid;
  width: 100%;
  max-width: 1248px;
  grid-gap: 16px;
  grid-template-areas:
    "pledges-presets-form"
    "pledges-configuration-form"
    "pledges-import-module";
  margin: auto;
}
#pledges-presets-form {
  grid-area: pledges-presets-form;
}
#pledges-configuration-form {
  grid-area: pledges-configuration-form;
}
#pledges-import-module {
  grid-area: pledges-import-module;
}
@media screen and (min-width: 1024px) {
  .edit-show-pledges-page__grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "pledges-presets-form pledges-import-module"
      "pledges-configuration-form pledges-import-module";
  }
}
