.show-color-pledge-form {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
}

@media screen and (min-width: 768px) {
  .show-color-pledge-form {
    grid-template-columns: 1fr 1fr 1fr;
  }
}