.base-csv-loader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  position: relative;
}
.base-csv-loader__hidden-input {
  position: absolute;
  height: 0;
  width: 0;
}
.base-csv-loader__input-container {
  height: 100px;
  width: 100%;
  background-color: white;
  border: 1px dotted grey;
  border-radius: 8px;
  color: #848aae;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 32px;
}
.base-csv-loader__input-container--drag-over {
  background-color: rgba(100, 148, 237, 0.15);
}

.base-csv-loader__input-container__text {
  text-align: center;
  margin-left: 16px;
}
.base-csv-loader__input-container__text__action {
  color: #848aae;
  font-weight: bold;
}
.base-csv-loader__input-container__text__action:hover {
  cursor: pointer;
  text-decoration: underline;
}
