@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");

/* Vars */
:root {
  --color-light: white;
  --color-dark: #222328;
}
* {
  box-sizing: border-box;
}
p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
label,
input,
textarea {
  font-family: "Inter";
  color: var(--color-dark);
}
button {
  font-family: "Inter";
}
html {
  overflow-y: scroll;
  background-color: #f4f4f4;
}
.app {
  padding-top: 48px;
}

@media screen and (min-width: 768px) {
  .app {
    padding-top: 0;
    padding-left: 64px;
  }
}
/*
  Z-INDEXES
*/
.menu-item__text {
  z-index: 5;
}
.menu {
  z-index: 4;
}
.base-notification {
  z-index: 3;
}
.page-header {
  z-index: 2;
}
.base-loading-curtain {
  z-index: 1;
}
