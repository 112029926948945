.live-stats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 16px;
}
.live-stats > span {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.live-stats > span > span {
  color: #2ca795;
  font-weight: bold;
  margin-top: 4px;
  font-size: 14px;
}
@media screen and (min-width: 600px) {
  .live-stats {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
}
@media screen and (min-width: 1024px) {
  .live-stats > span {
    flex-direction: row;
    justify-content: center;
    font-size: 16px;
  }
  .live-stats > span > span {
    margin-top: 0px;
    margin-left: 8px;
    font-size: 16px;
  }
}
