.base-text-area {
  width: 100%;
}
.base-text-area label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-right: 16px;
  margin-bottom: 8px;
}
.base-text-area textarea {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  resize: none;
  white-space: pre-wrap;
}
