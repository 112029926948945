.base-datetime-picker {
  width: 100%;
}
.base-datetime-picker label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-right: 16px;
  margin-bottom: 8px;
}
.react-datetime-picker__wrapper {
  padding: 9px;
  border-radius: 2px;
}
