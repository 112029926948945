.live-chat-monitor__messages-list {
  min-height: 105px;
  height: calc(100vh - 535px);
  background-color: whitesmoke;
  margin-top: 8px;
  border-radius: 8px;
  overflow-y: auto;
  padding: 8px;
  display: flex;
  flex-direction: column;
}

.live-chat-monitor__messenger__input {
  margin: 8px 0;
}
.live-chat-monitor__messages-list__item {
  background-color: var(--color-light);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
}
.live-chat-monitor__messages-list__item:first-of-type {
  margin-top: auto;
}
.live-chat-monitor__messages-list__item--disabled {
  opacity: 0.5;
}
.live-chat-monitor__messages-list__item__top {
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.live-chat-monitor__messages-list__item__top__action:hover {
  filter: brightness(1.3);
  cursor: pointer;
}
.live-chat-monitor__messages-list__item__content {
  font-size: 14px;
  margin-top: 4px;
  white-space: pre-wrap;
}
