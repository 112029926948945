.page-header {
  padding: 12px 16px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.75);
  position: fixed;
  width: 100%;
  top: 48px;
}
.page-header__content {
  margin: auto;
  max-width: 1248px;
  font-weight: 800;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-header__content > span {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}
.page-header__content__actions {
  display: flex;
  justify-content: space-between;
}
@media screen and (min-width: 768px) {
  .page-header {
    padding: 24px 16px;
    top: 0;
    width: calc(100% - 64px);
  }
  .page-header__content {
    font-size: 24px;
  }
  .page-header__content > span {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 24px;
  }
}
