.projects-configurator {
  height: calc(100vh - 128px);
}
.projects-configurator--editor {
  height: 553px;
}
.projects-configurator__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.projects-configurator__editor {
  margin-top: 16px;
  display: grid;
  grid-template-areas:
    "project-logo-editor"
    "project-editor-form";
  grid-template-columns: 1fr;
  grid-gap: 16px;
}

@media screen and (min-width: 960px) {
  .projects-configurator__editor {
    margin-top: 16px;
    display: grid;
    grid-template-areas: "project-logo-editor project-editor-color project-editor-form";
      grid-template-columns: 1fr 0.5fr 1fr;
      grid-gap: 32px;
  }
  .projects-configurator--editor {
    height: 353px;
  }
}
.projects-configurator__editor__form {
  padding-top: 10px;
}
.projects-configurator__editor__color {
  padding-top: 10px;
}
.projects-configurator__editor__form__input {
  margin-bottom: 32px;
}
.projects-configurator__editor__form__actions {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
}
.projects-configurator__configurator {
  background-color: whitesmoke;
  margin-top: 16px;
  padding: 8px;
  height: calc(100% - 46px);
  border-radius: 8px;
  overflow-y: auto;
}

.projects-configurator__configurator__item {
  background-color: var(--color-light);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 8px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: grab;
  margin-bottom: 8px;
}
.projects-configurator__configurator__item__heading {
  display: flex;
  align-items: center;
}
.projects-configurator__configurator__item__heading__infos {
  display: flex;
  flex-direction: column;
}
.projects-configurator__configurator__item__heading__infos
  > span:first-of-type {
  font-weight: bold;
  margin-bottom: 2px;
}
.projects-configurator__configurator__item__heading__infos
  > span:first-of-type {
  font-weight: bold;
  margin-bottom: 2px;
}
.projects-configurator__configurator__item__heading__infos > span:last-of-type {
  font-size: 14px;
}
.projects-configurator__configurator__item__heading__infos
  > span:last-of-type
  > span {
  color: #2ca795;
  font-weight: bold;
}
.projects-configurator__configurator__item__heading__logo {
  height: 44px;
  width: 44px;
  background-repeat: no-repeat;
  background-size: calc(100% - 8px);
  border: 1px solid grey;
  background-position: 50%;
  border-radius: 8px;
  margin-right: 16px;
}
