@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menu {
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: var(--color-dark);
  top: 0;
  left: 0;
  -webkit-transform: translateY(calc(-100% + 48px));
          transform: translateY(calc(-100% + 48px));
}
.menu--active {
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.menu-desktop {
  display: none;
}
.menu-mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 100%;
}
.menu-mobile--active {
  justify-content: flex-start;
}
.menu-mobile__content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
}
.menu-mobile__content__hamburger {
  cursor: pointer;
  color: var(--color-light);
  display: flex;
  align-items: center;
}

.menu-mobile--active__content {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.menu-mobile--active__content__header {
  padding: 8px 16px;
  width: 100%;
}
.menu-mobile--active__content__header__close-button {
  cursor: pointer;
}
.menu-mobile--active__content__menu-items {
  height: calc(100% - 64px);
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (min-width: 768px) {
  .menu {
    width: 64px;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    display: flex;
    transition: -webkit-transform 0ms ease;
    transition: transform 0ms ease;
    transition: transform 0ms ease, -webkit-transform 0ms ease;
  }
  .menu-desktop {
    display: block;
  }
  .menu-mobile {
    display: none;
  }
  .menu-desktop__logo-container {
    width: 100%;
    height: 80px;
    padding: 24px 16px 24px 16px;
    display: flex;
  }
  .menu-desktop__navigation-container {
    height: calc(100% - 160px);
    width: 100%;
    padding: 16px;
    overflow-y: auto;
    /* overflow-x: hidden; */
  }
  .menu-desktop__quit-container {
    content: " ";
    width: 100%;
    height: 80px;
    padding: 24px 16px 24px 16px;
    display: flex;
  }
}

.base-logo {
  width: 32px;
  height: 32px;
  /* background-color: green; */
  object-fit: contain;
}


.menu-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
}
.menu-item--top-separator {
  border-top: 1px solid grey;
  padding-top: 16px;
}
.menu-item__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  margin-right: 8px;
}
.menu-item__text {
  color: var(--color-light);
}
.menu-item--muted .menu-item__icon,
.menu-item--muted .menu-item__text {
  opacity: 0.33;
  transition: opacity 200ms ease;
}
.menu-item--muted:hover .menu-item__icon,
.menu-item--muted:hover .menu-item__text {
  opacity: 1;
}

.menu-item__condensed .menu-item__icon {
  border: 1px solid var(--color-light);
  margin-right: 0px;
}
.menu-item__condensed .menu-item__text {
  display: none;
  position: absolute;
}
.menu-item__condensed:hover .menu-item__icon {
  background-color: rgba(255, 255, 255, 0.05);
}
.menu-item__condensed:hover .menu-item__text {
  display: block;
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 8px;
  font-size: 14px;
  border: 1px solid grey;
  margin-left: 56px;
  white-space: nowrap;
  overflow: hidden;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #848aae;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #848aae transparent transparent transparent;
}
.lds-ring--mini {
  width: 16px;
  height: 16px;
}
.lds-ring--mini div {
  width: 16px;
  height: 16px;
  margin: 0px;
  border: 2px solid #848aae;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.base-button--mobile-condensed {
  padding: 8px 16px;
}
.base-button--mobile-condensed .base-button__content__text {
  display: none;
}
.base-button--mobile-condensed .base-button__content__icon {
  margin: 0;
}

.base-button {
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 16px;
  font-weight: bold;
  border: none;
  font-size: 12px;
  transition: all 200ms ease;
}

.base-button:hover {
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
}
.base-button:active {
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}
.base-button--disabled {
  opacity: 0.3;
}
.base-button--disabled:hover,
.base-button--disabled:active {
  -webkit-filter: brightness(1);
          filter: brightness(1);
}

.base-button--is-loading .base-button__content .base-button__content__text,
.base-button--is-loading .base-button__content .base-button__content__icon {
  opacity: 0.2;
}
.base-button__content {
  position: relative;
  display: flex;
  justify-content: center;
}
.base-button__content__text {
  color: white;
  white-space: nowrap;
  overflow: hidden;
}
.base-button__content__icon {
  color: white;
  margin-right: 4px;
}
.base-button__content__loading {
  position: absolute;
}
@media screen and (min-width: 768px) {
  .base-button--mobile-condensed {
    padding: 8px 16px;
  }
  .base-button--mobile-condensed .base-button__content__text {
    display: block;
  }
  .base-button--mobile-condensed .base-button__content__icon {
    margin-right: 8px;
  }
}

.page-header {
  padding: 12px 16px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.75);
  position: fixed;
  width: 100%;
  top: 48px;
}
.page-header__content {
  margin: auto;
  max-width: 1248px;
  font-weight: 800;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-header__content > span {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}
.page-header__content__actions {
  display: flex;
  justify-content: space-between;
}
@media screen and (min-width: 768px) {
  .page-header {
    padding: 24px 16px;
    top: 0;
    width: calc(100% - 64px);
  }
  .page-header__content {
    font-size: 24px;
  }
  .page-header__content > span {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 24px;
  }
}

.page-content {
  width: 100%;
  margin-top: 56px;
  padding: 16px;
  padding-bottom: 32px;
}
@media screen and (min-width: 768px) {
  .page-content {
    margin-top: 77px;
  }
}

.show-card-calendar {
  background-color: white;
  width: 56px;
  height: 64px;
  border-radius: 8px;

  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.show-card-calendar__day-container {
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  opacity: 0.8;
}
.show-card-calendar__month-container {
  font-size: 14px;
  line-height: 16px;
  opacity: 0.8;
}
.show-card-calendar__year-container {
  font-size: 12px;
  line-height: 14px;
  opacity: 0.5;
}

.show-card-infos-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  font-weight: bold;
  border: 1px solid #848aae;
  border-radius: 64px;
  font-size: 12px;
  padding: 1px 8px;
  min-width: 90px;
  margin-left: 8px;
}
.show-card-infos-indicator span {
  white-space: nowrap;
  color: white;
}
.show-card-infos-indicator__red-circle {
  background-color: red;
  border-radius: 64px;
  height: 10px;
  width: 10px;
  margin-right: 4px;
}
.show-card-infos-indicator__countdown {
  min-width: 4rem;
  text-align: center;
}

.show-card-infos__place-container {
  display: block;
  font-weight: bold;
  opacity: 0.75;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 14px;
}
.show-card-infos__name-container {
  font-size: 22px;
  font-weight: bold;
  color: white;
  opacity: 0.9;
  margin: 4px 0 8px 0;
}
.show-card-infos__subtitle-and-indicator {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.show-card-infos__subtitle {
  font-weight: bold;
  opacity: 0.75;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 14px;
}

.show-card {
  position: relative;
  width: 100%;
  background-size: cover;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  cursor: pointer;
}
.show-card:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.show-card__content {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 16px;
  background: linear-gradient(transparent, #00000060, #00000099, #000000f9);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: white;
}
.show-card__content__cal-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.show-card_content_infos-container {
  width: 100%;
}
.show-card__content__cal-container__publish-info {
  background-color: #a77c2c;
  padding: 6px 8px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
}
.show-card__content__cal-container__publish-info--published {
  background-color: #2ca795;
}
.show-card__content__tag-container {
    diplay: flex;
    flex-direction: column;
}
.show-card__content__duplicate {
    background-color: #343434;
    padding: 6px 8px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    margin-top: 6px;
}
.show-card__content__multiplex-info {
    background-color: #473891;
    padding: 6px 8px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    margin-top: 6px;
    color: white;
    text-decoration: none;
}

.base-loading-curtain {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-page__shows-grid {
  max-width: 1248px;
  margin: auto;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(1, 1);
  grid-gap: 16px;
}

.home-page__shows-grid__more-show {
  cursor: pointer;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #848aae;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.home-page__shows-grid__more-show span {
  color: #848aae;
}
.home-page__shows-grid__more-show:hover span {
  text-decoration: underline;
}
.home-page__shows-grid__more-show:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
@media screen and (min-width: 550px) {
  .home-page__shows-grid {
    grid-template-columns: repeat(2, calc((100% - 16px) / 2));
  }
}

@media screen and (min-width: 950px) {
  .home-page__shows-grid {
    grid-template-columns: repeat(3, calc((100% - 32px) / 3));
  }
}
@media screen and (min-width: 1248px) {
  .home-page__shows-grid {
    grid-template-columns: repeat(4, calc((100% - 48px) / 4));
  }
}

.base-card {
  content: "Card";
  background-color: var(--color-light);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 16px;
  border-radius: 8px;
}
.base-card--tight {
  padding: 8px;
}

.base-input {
  width: 100%;
}
.base-input label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-right: 16px;
  margin-bottom: 8px;
}
.base-input input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
}

.new-show-page__content {
  width: 100%;
  max-width: 1248px;
  margin: auto;
}
.new-show-page__content__input {
  max-width: 450px;
}

.base-toggle {
  position: relative;
}

.base-toggle__checkbox {
  cursor: pointer;
  opacity: 1;
  position: absolute;
  top: 1px;
  left: 0;
  width: 50px;
  height: 28px;
  margin: 0;
  opacity: 0;
}
.base-toggle__toggler {
  background-color: whitesmoke;
  height: 30px;
  width: 50px;
  border-radius: 60px;
  border: 1px solid #e4e4e4;
  transition: background-color 0.2s ease;
}
.base-toggle__toggler__dot {
  pointer-events: none;
  content: "";
  background-color: white;
  height: 28px;
  width: 28px;
  border-radius: 60px;
  border: 1px solid lightgray;
  -webkit-transform: translateX(-2px);
          transform: translateX(-2px);
  transition: -webkit-transform 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  transition: transform 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  transition: transform 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6), -webkit-transform 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}
.base-toggle__checkbox:checked + .base-toggle__toggler {
  background-color: cornflowerblue;
}
.base-toggle__checkbox:checked
  + .base-toggle__toggler
  > .base-toggle__toggler__dot {
  -webkit-transform: translateX(22px);
          transform: translateX(22px);
}

.base-label {
  font-weight: 600;
  font-size: 16px;
  margin-right: 16px;
}
.base-label--small {
  font-size: 14px;
}

.published-selector {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.on-place-selector {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.on-remote-selector {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-show-infos-page__grid {
  display: grid;
  width: 100%;
  max-width: 1248px;
  grid-gap: 16px;
  grid-template-areas:
    "published-selector"
    "on-place-selector"
    "on-remote-selector"
    "show-infos-form"
    "show-color-pledge-form"
    "is-lnbc-selector"
    "delete-show-action";
  margin: auto;
}

#published-selector {
  grid-area: published-selector;
}
#on-place-selector {
  grid-area: on-place-selector;
}
#on-remote-selector {
  grid-area: on-remote-selector;
}
#show-infos-form {
  grid-area: show-infos-form;
}
#delete-show-action {
  grid-area: delete-show-action;
}
#is-lnbc-selector {
  grid-area: is-lnbc-selector;
}
#show-color-pledge-form {
  grid-area: show-color-pledge-form;
}
#preview-counter {
  grid-area: preview-counter;
}
.form-field {
  width: 100%;
  height: 72px;
  background-color: cornflowerblue;
}
@media screen and (min-width: 768px) {
  .edit-show-infos-page__grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "published-selector published-selector"
      "on-place-selector  on-remote-selector"
      "show-infos-form show-infos-form"
      "show-color-pledge-form show-color-pledge-form"
      "preview-counter preview-counter"
      "delete-show-action delete-show-action";
  }
}

.base-text-area {
  width: 100%;
}
.base-text-area label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-right: 16px;
  margin-bottom: 8px;
}
.base-text-area textarea {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  resize: none;
  white-space: pre-wrap;
}

.base-datetime-picker {
  width: 100%;
}
.base-datetime-picker label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-right: 16px;
  margin-bottom: 8px;
}
.react-datetime-picker__wrapper {
  padding: 9px;
  border-radius: 2px;
}

.show-infos-form {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
}

@media screen and (min-width: 768px) {
  .show-infos-form {
    grid-template-columns: 1fr 1fr;
  }
}

.base-input-color {
    width: 100%;
}

.base-input-color-flex {
    display: flex;
    justify-content: flex-start;
}


.base-input-color-before-input {
    overflow: hidden;
}

.base-input-color label {
    display: block;
    font-weight: 600;
    font-size: 14px;
    margin-right: 16px;
    margin-bottom: 8px;
}


.base-input-color-live-transparency {
    margin-left: 2em;
    height: 2em;
    border-radius: 50px;
}
.delete-show-action {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.show-color-pledge-form {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
}

@media screen and (min-width: 768px) {
  .show-color-pledge-form {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.counter-page__main__counter {
    /* width: 100vw; */
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.counter-page__main__counter__show-total-pledges {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.counter-page__main__counter__show-total-pledges span:first-of-type {
    font-size: 16px;
    margin-bottom: 8px;
}

.counter-page__main__counter__show-total-pledges span:last-of-type {
    font-size: 24px;
    font-weight: bold;
}

.counter-page__main__counter__project-counter {
    width: 100%;
}

.counter-page__main__counter__project-counter__current-project-name {
    display: block;
    font-size: 48px;
    margin-bottom: 32px;
    text-align: center;
}

.counter-page__main__counter__project-counter__logo {
    height: 256px;
    /* width: 128px; */
    margin: auto;
    margin-bottom: 16px;
    border-radius: 4px;
    /* border: 1px solid var(--ion-color-primary); */
    padding: 2px;
    aspect-ratio: 16/9;
}

.counter-page__main__counter__project-counter__logo ion-img {
    width: 100%;
    height: 100%;
}

.counter-page__main__counter__project-counter__amount {
    /* border: 1px solid var(--ion-color-primary); */
    text-align: center;
    font-size: 80px;
    border-radius: 8px;
    max-width: 600px;
    margin: auto;
}

.counter-page__main__counter__project-counter__progress-bar {
    width: 100%;
    max-width: 1440px;
    padding: 0 64px;
}

.counter-page__main__counter__project-counter__progress-bar__bar {
    width: 100%;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 90px;
    overflow: hidden;
}

.counter-page__main__counter__project-counter__progress-bar__bar__inner {
    width: 100%;
    height: 100%;

}

.counter-page__main__counter__project-counter__progress-bar__percentage {
    font-size: 32px;
    margin-bottom: 8px;
}

.counter-page__main__counter__project-counter__progress-bar__percentage div {
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
}

.counter-page__main__counter__project-counter__progress-bar__target {
    display: flex;
    justify-content: space-between;
    font-size: 32px;
    margin-top: 8px;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
}
.base-image-input {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  position: relative;
}
.base-image-input__hidden-input {
  position: absolute;
  height: 0;
  width: 0;
}
.base-image-input__visualizer {
  height: 200px;
  margin-bottom: 16px;
  width: 100%;
  background-color: #f8f8f8;
  border: 1px solid grey;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-size: calc(100% - 8px);
  background-position: 50%;
}
.base-image-input__input-container {
  height: 100px;
  width: 100%;
  background-color: white;
  border: 1px dotted grey;
  border-radius: 8px;
  color: #848aae;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
}
.base-image-input__input-container--drag-over {
  background-color: rgba(100, 148, 237, 0.15);
}

.base-image-input__input-container__text {
  text-align: center;
  margin-top: 16px;
}
.base-image-input__input-container__text__action {
  color: #848aae;
  font-weight: bold;
}
.base-image-input__input-container__text__action:hover {
  cursor: pointer;
  text-decoration: underline;
}

.base-image-input__delete-container {
  height: 100%;
  width: 100%;
  background-color: white;
  border: 1px dotted grey;
  border-radius: 8px;
  color: #848aae;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  margin-top: 1rem;
}
.base-image-input__delete-container__text {
  margin-top: 1rem;
  text-align: center;
  color: #848aae;
  font-weight: bold;
  cursor: pointer;
}

@media screen and (min-width: 560px) {
  .base-image-input {
    flex-direction: row;
      grid-gap: 1rem;
      gap: 1rem;
  }
  .base-image-input__visualizer {
    margin-bottom: 0;
    min-width: 200px;
    width: 200px;
  }
  .base-image-input__input-container {
    height: 200px;
    width: calc(100% - 216px);
  }
    .base-image-input__delete-container {
      height: 200px;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin-top: 0;
    }
}



.stream-configurator__form__stream-activator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
}
.stream-configurator__form__stream-visualizer {
  margin-top: 16px;
}
.stream-configurator__form__stream-visualizer__visualizer {
  margin-top: 16px;
  width: 100%;
  height: 323px;
  background-color: black;
  border-radius: 4px;
  overflow: hidden;
}

.edit-show-medias-page__grid {
  display: grid;
  width: 100%;
  max-width: 1248px;
  grid-gap: 16px;

  grid-template-areas:
    "cover-selector"
    "logo-selector"
    "stream-configurator";
  margin: auto;
}
#cover-selector {
  grid-area: cover-selector;
}
#logo-selector {
  grid-area: logo-selector;
}
#stream-configurator {
  grid-area: stream-configurator;
}
@media screen and (min-width: 1024px) {
  .edit-show-medias-page__grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "cover-selector stream-configurator"
      "logo-selector stream-configurator";
  }
}

.base-select-input {
  width: 100%;
}
.base-select-input label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-right: 16px;
  margin-bottom: 8px;
}
.base-select-input select {
  width: 100%;
  padding: 8px;
  font-size: 16px;
}

.pledges-configuration-form__form {
  margin-top: 32px;
}
.pledges-configuration-form__form__toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.base-csv-loader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  position: relative;
}
.base-csv-loader__hidden-input {
  position: absolute;
  height: 0;
  width: 0;
}
.base-csv-loader__input-container {
  height: 100px;
  width: 100%;
  background-color: white;
  border: 1px dotted grey;
  border-radius: 8px;
  color: #848aae;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 32px;
}
.base-csv-loader__input-container--drag-over {
  background-color: rgba(100, 148, 237, 0.15);
}

.base-csv-loader__input-container__text {
  text-align: center;
  margin-left: 16px;
}
.base-csv-loader__input-container__text__action {
  color: #848aae;
  font-weight: bold;
}
.base-csv-loader__input-container__text__action:hover {
  cursor: pointer;
  text-decoration: underline;
}

.pledges-import-module__form__toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.pledges-import-module__form__module {
  margin-top: 16px;
}
.pledges-import-module__form__module__counter {
  border: 1px solid rgb(200, 200, 200);
  border-radius: 8px;
  height: 232px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pledges-import-module__form__module__counter span:first-of-type {
  font-size: 64px;
  font-weight: bold;
}
.pledges-import-module__form__module__counter span:last-of-type {
  font-size: 20px;
  font-weight: bold;
  margin-top: 16px;
}

.pledges-presets-form__form__presets-activator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
}
.pledges-presets-form__form__presets-grid {
  display: grid;
  grid-template-areas:
    "first-pledge-preset second-pledge-preset"
    "third-pledge-preset fourth-pledge-preset";
  grid-gap: 16px;
}

.edit-show-pledges-page__grid {
  display: grid;
  width: 100%;
  max-width: 1248px;
  grid-gap: 16px;
  grid-template-areas:
    "pledges-presets-form"
    "pledges-configuration-form"
    "pledges-import-module";
  margin: auto;
}
#pledges-presets-form {
  grid-area: pledges-presets-form;
}
#pledges-configuration-form {
  grid-area: pledges-configuration-form;
}
#pledges-import-module {
  grid-area: pledges-import-module;
}
@media screen and (min-width: 1024px) {
  .edit-show-pledges-page__grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "pledges-presets-form pledges-import-module"
      "pledges-configuration-form pledges-import-module";
  }
}

.projects-configurator {
  height: calc(100vh - 128px);
}
.projects-configurator--editor {
  height: 553px;
}
.projects-configurator__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.projects-configurator__editor {
  margin-top: 16px;
  display: grid;
  grid-template-areas:
    "project-logo-editor"
    "project-editor-form";
  grid-template-columns: 1fr;
  grid-gap: 16px;
}

@media screen and (min-width: 960px) {
  .projects-configurator__editor {
    margin-top: 16px;
    display: grid;
    grid-template-areas: "project-logo-editor project-editor-color project-editor-form";
      grid-template-columns: 1fr 0.5fr 1fr;
      grid-gap: 32px;
  }
  .projects-configurator--editor {
    height: 353px;
  }
}
.projects-configurator__editor__form {
  padding-top: 10px;
}
.projects-configurator__editor__color {
  padding-top: 10px;
}
.projects-configurator__editor__form__input {
  margin-bottom: 32px;
}
.projects-configurator__editor__form__actions {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
}
.projects-configurator__configurator {
  background-color: whitesmoke;
  margin-top: 16px;
  padding: 8px;
  height: calc(100% - 46px);
  border-radius: 8px;
  overflow-y: auto;
}

.projects-configurator__configurator__item {
  background-color: var(--color-light);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 8px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: grab;
  margin-bottom: 8px;
}
.projects-configurator__configurator__item__heading {
  display: flex;
  align-items: center;
}
.projects-configurator__configurator__item__heading__infos {
  display: flex;
  flex-direction: column;
}
.projects-configurator__configurator__item__heading__infos
  > span:first-of-type {
  font-weight: bold;
  margin-bottom: 2px;
}
.projects-configurator__configurator__item__heading__infos
  > span:first-of-type {
  font-weight: bold;
  margin-bottom: 2px;
}
.projects-configurator__configurator__item__heading__infos > span:last-of-type {
  font-size: 14px;
}
.projects-configurator__configurator__item__heading__infos
  > span:last-of-type
  > span {
  color: #2ca795;
  font-weight: bold;
}
.projects-configurator__configurator__item__heading__logo {
  height: 44px;
  width: 44px;
  background-repeat: no-repeat;
  background-size: calc(100% - 8px);
  border: 1px solid grey;
  background-position: 50%;
  border-radius: 8px;
  margin-right: 16px;
}

.edit-show-projects-page__grid {
  display: grid;
  width: 100%;
  max-width: 1248px;
  grid-gap: 16px;
  grid-template-areas:
    /* "no-projects-selector" */ "projects-configurator";
  margin: auto;
}
#projects-configurator {
  grid-area: projects-configurator;
}
/* #no-projects-selector {
  grid-area: no-projects-selector;
} */

.registrations-custom-configurator__new-action {
  margin-top: 16px;
  width: 100%;
}
.registrations-custom-configurator__configurator {
  background-color: whitesmoke;
  margin-top: 16px;
  padding: 8px;
  height: calc(100vh - 245px);
  border-radius: 8px;
  overflow-y: auto;
  min-height: 250px;
}
.registrations-custom-configurator__configurator__item {
  background-color: var(--color-light);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
}
.registrations-custom-configurator__configurator__item__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
}
.registrations-custom-configurator__configurator__item__header__input {
  width: 100%;
  margin-right: 16px;
}
.registrations-custom-configurator__configurator__item__header__required {
  margin-right: 16px;
}
.registrations-custom-configurator__configurator__item__header__required
  > span {
  font-size: 12px;
  text-align: center;
  width: 50px;
  display: block;
  margin-bottom: 2px;
}
.registrations-custom-configurator__configurator__item__header__delete {
  align-self: flex-start;
  cursor: pointer;
  transition: 200ms all ease;
}
.registrations-custom-configurator__configurator__item__header__delete:hover {
  -webkit-filter: brightness(1.3);
          filter: brightness(1.3);
}
.registrations-custom-configurator__configurator__item__type-selector {
  background-color: rgb(230, 230, 230);
  padding: 2px;
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  border: 1px solid #e4e4e4;
}
.registrations-custom-configurator__configurator__item__type-selector__selector {
  width: 33.3%;
  text-align: center;
  padding: 2px;
  cursor: pointer;
}
.registrations-custom-configurator__configurator__item__type-selector__selector--active {
  background-color: white;
  border-radius: 16px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.registrations-preset-selector__toggle-labels {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  font-size: 12px;
  margin-top: 16px;
}
.registrations-preset-selector__toggle-labels__labels {
  width: 120px;
  display: flex;
  justify-content: space-between;
}
.registrations-preset-selector__toggle-labels span {
  width: 50%;
  max-width: 50px;
  text-align: center;
}
.registrations-preset-selector__toggle-labels span:first-of-type {
}
.registrations-preset-selector__form__toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.registrations-preset-selector__form__toggle__toggles {
  display: flex;
  justify-content: space-between;

  width: 120px;
}

.edit-show-registrations-page__grid {
  display: grid;
  width: 100%;
  max-width: 1248px;
  grid-gap: 16px;
  grid-template-areas:
    "registrations-preset-selector"
    "registrations-custom-configurator";
  margin: auto;
}
#registrations-preset-selector {
  grid-area: registrations-preset-selector;
}
#registrations-custom-configurator {
  grid-area: registrations-custom-configurator;
}
@media screen and (min-width: 1024px) {
  .edit-show-registrations-page__grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "registrations-preset-selector registrations-custom-configurator";
  }
}

.live-chat-monitor__messages-list {
  min-height: 105px;
  height: calc(100vh - 535px);
  background-color: whitesmoke;
  margin-top: 8px;
  border-radius: 8px;
  overflow-y: auto;
  padding: 8px;
  display: flex;
  flex-direction: column;
}

.live-chat-monitor__messenger__input {
  margin: 8px 0;
}
.live-chat-monitor__messages-list__item {
  background-color: var(--color-light);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
}
.live-chat-monitor__messages-list__item:first-of-type {
  margin-top: auto;
}
.live-chat-monitor__messages-list__item--disabled {
  opacity: 0.5;
}
.live-chat-monitor__messages-list__item__top {
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.live-chat-monitor__messages-list__item__top__action:hover {
  -webkit-filter: brightness(1.3);
          filter: brightness(1.3);
  cursor: pointer;
}
.live-chat-monitor__messages-list__item__content {
  font-size: 14px;
  margin-top: 4px;
  white-space: pre-wrap;
}

.live-closing-action {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.live-current-project-selector--no-projects {
  opacity: 0.5;
  pointer-events: none;
}

.live-current-project-selector__list {
  background-color: whitesmoke;
  border-radius: 8px;
  margin-top: 8px;
  padding: 8px;
  min-height: 320px;
  height: calc(100vh - 320px);
  /* NOTE adjust height */
  overflow-y: auto;
}
.live-current-project-selector__list__item {
  background-color: var(--color-light);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 8px;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
  border: 2px solid transparent;
}
.live-current-project-selector__list__item--active {
  border-color: #2ca795;
}

.live-current-project-selector__list__item__logo {
  height: 44px;
  min-width: 44px;
  background-repeat: no-repeat;
  background-size: calc(100% - 8px);
  border: 1px solid grey;
  background-position: 50%;
  border-radius: 8px;
  margin-right: 16px;
  overflow: hidden;
}
.live-current-project-selector__list__item__logo__counter-link {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 100ms ease-in-out;
}
.live-current-project-selector__list__item__logo__counter-link:hover {
  background-color: rgba(255, 255, 255, 0.88);
  opacity: 1;
}
.live-current-project-selector__list__item__infos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.live-current-project-selector__list__item__infos__name {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 8px;
}
.live-current-project-selector__list__item__infos__goal {
  text-align: right;
  font-weight: bold;
  font-size: 14px;
}
.live-current-project-selector__list__item__infos__goal > span {
  color: #848aae;
}
.live-current-project-selector__list__item__infos__goal > span:last-of-type {
  color: #2ca795;
}
.live-current-project-selector__list__item__infos__goal--reached {
  color: #2ca795 !important;
}

.live-pledges-monitor__project-selector {
  margin-top: 8px;
}
.live-pledges-monitor__pledges-list {
  background-color: whitesmoke;
  border-radius: 8px;
  margin-top: 8px;
  padding: 8px 0 8px 8px;
  min-height: 160px;
  height: calc(100vh - 470px);
  margin-bottom: 8px;
  overflow-y: auto;
}
.live-pledges-monitor__pledges-list--no-projects {
  height: calc(100vh - 402px);
}
.live-pledges-monitor__pledges-list__item {
  background-color: var(--color-light);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  border: 2px solid transparent;
}
.live-pledges-monitor__pledges-list__item__top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}
.live-pledges-monitor__pledges-list__item__top__delete:hover {
  transition: all 250ms ease;
  cursor: pointer;
  -webkit-filter: brightness(1.3);
          filter: brightness(1.3);
}
.live-pledges-monitor__pledges-list__item__bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.live-pledges-monitor__pledges-list__item__bottom span:first-of-type {
  color: gray;
  font-style: italic;
}
.live-pledges-monitor__pledges-list__item__bottom span:last-of-type {
  color: #2ca795;
  font-weight: bold;
}
.live-pledges-monitor__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.live-pledges-monitor__actions__amounts-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 8px;
}
.live-pledges-monitor__actions__free-amount {
  margin-left: 8px;
  width: 100%;
}

.live-pledges-monitor__actions__free-amount button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.live-stats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 16px;
}
.live-stats > span {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.live-stats > span > span {
  color: #2ca795;
  font-weight: bold;
  margin-top: 4px;
  font-size: 14px;
}
@media screen and (min-width: 600px) {
  .live-stats {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
}
@media screen and (min-width: 1024px) {
  .live-stats > span {
    flex-direction: row;
    justify-content: center;
    font-size: 16px;
  }
  .live-stats > span > span {
    margin-top: 0px;
    margin-left: 8px;
    font-size: 16px;
  }
}

.live-manager-page__grid {
  display: grid;
  width: 100%;
  max-width: 1248px;
  grid-gap: 16px;
  grid-template-areas:
    "live-stats"
    "live-current-project-selector"
    "live-pledges-monitor"
    "live-chat-monitor"
    "live-closing-action";
  margin: auto;
}
.live-manager-page__grid--no-projects {
  grid-template-areas:
    "live-stats"
    "live-pledges-monitor"
    "live-chat-monitor"
    "live-closing-action";
}
#live-stats {
  grid-area: live-stats;
}
#live-current-project-selector {
  grid-area: live-current-project-selector;
}
#live-pledges-monitor {
  grid-area: live-pledges-monitor;
}
#live-chat-monitor {
  grid-area: live-chat-monitor;
}
#live-closing-action {
  grid-area: live-closing-action;
}
@media screen and (min-width: 1024px) {
  .live-manager-page__grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "live-stats live-stats live-stats"
      "live-current-project-selector live-pledges-monitor live-chat-monitor"
      "live-closing-action live-closing-action live-closing-action";
  }
  .live-manager-page__grid--no-projects {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "live-stats live-stats"
      "live-pledges-monitor live-chat-monitor"
      "live-closing-action live-closing-action";
  }
}

/* Vars */
:root {
  --color-light: white;
  --color-dark: #222328;
}
* {
  box-sizing: border-box;
}
p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
label,
input,
textarea {
  font-family: "Inter";
  color: #222328;
  color: var(--color-dark);
}
button {
  font-family: "Inter";
}
html {
  overflow-y: scroll;
  background-color: #f4f4f4;
}
.app {
  padding-top: 48px;
}

@media screen and (min-width: 768px) {
  .app {
    padding-top: 0;
    padding-left: 64px;
  }
}
/*
  Z-INDEXES
*/
.menu-item__text {
  z-index: 5;
}
.menu {
  z-index: 4;
}
.base-notification {
  z-index: 3;
}
.page-header {
  z-index: 2;
}
.base-loading-curtain {
  z-index: 1;
}

.base-notification {
  position: fixed;
  bottom: -80px;
  right: 0;
  width: 100%;
  max-width: 400px;
  height: 80px;
  /* background-color: red; */
  padding: 8px;
  transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  transition: transform 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  transition: transform 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6), -webkit-transform 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}
.base-notification--is-active {
  -webkit-transform: translate3d(0px, -80px, 0);
          transform: translate3d(0px, -80px, 0);
}

.base-notification__notification {
  height: 100%;
  width: 100%;
  border: 1px solid rgba(225, 225, 225, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.base-notification__notification__content {
  width: 100%;
  font-weight: bold;
  padding: 16px;
}
.base-notification--success .base-notification__notification {
  border-color: #2ca795;
}

.base-notification--success .base-notification__notification__content {
  color: #2ca795;
}
.base-notification--error .base-notification__notification {
  border-color: #ce193d;
}

.base-notification--error .base-notification__notification__content {
  color: #ce193d;
}

.login-page {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-page__login-form {
  min-width: 300px;
  margin: 32px 0 32px 0;
}
.login-page__login-form div:first-of-type {
  margin-bottom: 16px;
}
.login-page__login-form div:last-of-type {
  margin-bottom: 16px;
}
.login-page__login-form__password-reset {
  font-size: 14px;
  color: cornflowerblue;
}
.login-page__login-form__password-reset:hover {
  cursor: pointer;
  text-decoration: underline;
}

.new-multiplex-page__content {
    width: 100%;
    max-width: 1248px;
    margin: auto;
}

.new-multiplex-page__content .base-card {
    margin-bottom: 1rem;
}
.multiplex-manager__grid {
    display: grid;
    width: 100%;
    max-width: 1248px;
    grid-gap: 16px;
    grid-template-areas:
            "multiplex-manager__infos"
            "multiplex-manager__count"
            "live-current-project-selector"
            "multiplex-manager__dashboard-list"
            "multiplex-manager__event-list";
    margin: auto;
}

#multiplex-manager__infos {
    grid-area: multiplex-manager__infos;
}

#multiplex-manager__count {
    grid-area: multiplex-manager__count;
}

#multiplex-manager__dashboard-list {
    grid-area: multiplex-manager__dashboard-list;
}

#multiplex-manager__show-list {
    grid-area: multiplex-manager__event-list;
}

.multiplex-manager__count {
    display: flex;
    justify-content: space-between;
}

.multiplex-manager__infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.multiplex-manager__count span,
.multiplex-manager__infos span{
    color: #2ca795;
    font-weight: bold;
    font-size: 16px;
}

.multiplex-manager__toggle {
    display: flex;
    align-items: center;
}

.multiplex-manager__toggle-label {
  margin: 0 0.5rem;
}

.multiplex-manager__dashboard-list__inner-container {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    background-color: whitesmoke;
    border-radius: 8px;
    margin-top: 8px;
    padding: 8px;
}

.multiplex-manager__dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.multiplex-manager__dashboard__title {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 8px;
}

.multiplex-manager__dashboard__progress {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 8px;
    text-align: end;
}

.multiplex-manager__dashboard__count {
    color: #848aae;
}

.multiplex-manager__dashboard__goal {
    color: #2ca795;
}

.multiplex-manager__show {
    padding: 0.5rem 1rem;
}

.multiplex-manager__show__info span {
    font-weight: bold;
}

@media screen and (min-width: 1024px) {
    .multiplex-manager__grid {
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas:
            "multiplex-manager__infos multiplex-manager__infos multiplex-manager__infos"
            "multiplex-manager__count multiplex-manager__count multiplex-manager__count"
            "live-current-project-selector multiplex-manager__dashboard-list multiplex-manager__event-list";
    }
}

.multiplex-current-dashboard-selector--no-projects {
    opacity: 0.5;
    pointer-events: none;
}

.multiplex-current-dashboard__list {
    background-color: whitesmoke;
    border-radius: 8px;
    margin-top: 8px;
    padding: 8px;
    min-height: 320px;
    height: calc(100vh - 320px);
    /* NOTE adjust height */
    overflow-y: auto;
}
.multiplex-current-dashboard-selector__list__item {
    background-color: var(--color-light);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 8px;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-bottom: 8px;
    border: 2px solid transparent;
}
.multiplex-current-dashboard-selector__list__item--active {
    border-color: #2ca795;
}

.multiplex-current-dashboard-selector__list__item__logo {
    height: 44px;
    min-width: 44px;
    background-repeat: no-repeat;
    background-size: calc(100% - 8px);
    border: 1px solid grey;
    background-position: 50%;
    border-radius: 8px;
    margin-right: 16px;
    overflow: hidden;
}
.multiplex-current-dashboard-selector__list__item__logo__counter-link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 100ms ease-in-out;
}
.multiplex-current-dashboard-selector__list__item__logo__counter-link:hover {
    background-color: rgba(255, 255, 255, 0.88);
    opacity: 1;
}
.multiplex-current-dashboard-selector__list__item__infos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}
.multiplex-current-dashboard-selector__list__item__infos__name {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 8px;
}
.multiplex-current-dashboard-selector__list__item__infos__goal {
    text-align: right;
    font-weight: bold;
    font-size: 14px;
}
.multiplex-current-dashboard-selector__list__item__infos__goal > span {
    color: #848aae;
}
.multiplex-current-dashboard-selector__list__item__infos__goal > span:last-of-type {
    color: #2ca795;
}
.multiplex-current-dashboard-selector__list__item__infos__goal--reached {
    color: #2ca795 !important;
}

.multiplex-current-dashboard-project-selector__list__item__infos__name span {
    font-weight: bold;
    text-transform: capitalize;
}

.multiplexes-page__multiplexes-grid {
    max-width: 1248px;
    margin: auto;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1);
    grid-gap: 16px;
}

.multiplexes-page__multiplexes-grid__more-multiplexes {
    cursor: pointer;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #848aae;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.multiplex-card {
    position: relative;
    width: 100%;
    background-size: cover;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 8px;
    cursor: pointer;
    background-position: center;
    background-color: #c8c8c8;
}
.multiplex-card__content {
    height: 300px;
    padding: 16px;
    background: linear-gradient(transparent, #00000060, #00000099, #000000f9);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
}
.multiplex-card__content__cal-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.multiplex-card__content__infos-container__title{
    font-size: 22px;
    font-weight: bold;
    color: white;
    opacity: 0.9;
    margin: 4px 0 8px 0;
}
.multiplex-card__content__infos-container__subtitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    opacity: 0.75;
    font-size: 14px;
    min-height: 17px;
}
.multiplexes-page__multiplexes-grid__more-multiplexes {
    cursor: pointer;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #848aae;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}
.multiplexes-page__multiplexes-grid__more-multiplexes span {
    color: #848aae;
}
.multiplexes-page__multiplexes-grid__more-multiplexes:hover span {
    text-decoration: underline;
}
.multiplexes-page__multiplexes-grid__more-multiplexes:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}


@media screen and (min-width: 550px) {
    .multiplexes-page__multiplexes-grid {
        grid-template-columns: repeat(2, calc((100% - 16px) / 2));
    }
}
@media screen and (min-width: 950px) {
    .multiplexes-page__multiplexes-grid {
        grid-template-columns: repeat(3, calc((100% - 32px) / 3));
    }
}
@media screen and (min-width: 1248px) {
    .multiplexes-page__multiplexes-grid {
        grid-template-columns: repeat(4, calc((100% - 48px) / 4));
    }
}
.edit-multiplex-dashboardss-page__grid {
    display: grid;
    width: 100%;
    max-width: 1248px;
    grid-gap: 16px;
    grid-template-areas:
        "dashboards-configurator";
    margin: auto;
}
.dashboards-configurator {
    height: calc(100vh - 128px);
}
.dashboards-configurator--editor {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.dashboards-configurator__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.dashboards-configurator__configurator {
    background-color: whitesmoke;
    margin-top: 16px;
    padding: 8px;
    height: calc(100% - 46px);
    border-radius: 8px;
    overflow-y: auto;
}
.dashboards-configurator__configurator__item {
    background-color: var(--color-light);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 8px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: grab;
    margin-bottom: 8px;
}
.dashboards-configurator__configurator__item__heading {
    display: flex;
    align-items: center;
}

.dashboards-configurator__editor {
    margin-top: 16px;
}
.dashboards-configurator__editor__form__input,
.dashboards-configurator__editor__logo-editor {
    margin-bottom: 16px;
}
.dashboards-configurator__editor__form__actions {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
}

.dashboards-configurator__configurator__item__heading {
    display: flex;
    align-items: center;
}
.dashboards-configurator__configurator__item__heading__infos {
    display: flex;
    flex-direction: column;
}
.dashboards-configurator__configurator__item__heading__infos
> span:first-of-type {
    font-weight: bold;
    margin-bottom: 2px;
}
.dashboards-configurator__configurator__item__heading__infos
> span:first-of-type {
    font-weight: bold;
    margin-bottom: 2px;
}
.dashboards-configurator__configurator__item__heading__infos > span:last-of-type {
    font-size: 14px;
}
.dashboards-configurator__configurator__item__heading__infos
> span:last-of-type
> span {
    color: #2ca795;
    font-weight: bold;
}
.dashboards-configurator__configurator__item__heading__logo {
    height: 44px;
    width: 44px;
    background-repeat: no-repeat;
    background-size: calc(100% - 8px);
    border: 1px solid grey;
    background-position: 50%;
    border-radius: 8px;
    margin-right: 16px;
}
.dashboards-configurator__project {
    padding: 1rem 0;
}
.dashboards-configurator__project:not(:last-child) {
    border-bottom: 1px solid rgba(190, 190, 190, 0.5);
}
.dashboards-configurator__project__remove {
    margin-right: 1rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
}
.projects-configurator__configurator__item__actions {
  display: flex;
}
.edit-multiplex-infos-page__grid {
    display: grid;
    width: 100%;
    max-width: 1248px;
    grid-gap: 16px;
    grid-template-areas:
    "multiplex-infos-form";
    margin: auto;
}

#multiplex-infos-form {
    grid-area: multiplex-infos-form;
}
.multiplex-infos-form {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr;
}

.multiplex-shows__event {
    padding: 1rem 0;
}
.multiplex-shows__event:not(:last-child) {
    border-bottom: 1px solid rgba(190, 190, 190, 0.5);
}

.multiplex-shows__event__remove {
    margin-right: 1rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.multiplex-shows__event__link {
    cursor: pointer;
}

.multiplex-color-picker {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    gap: 2rem;
}
.delete-multiplex-action {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
