.page-content {
  width: 100%;
  margin-top: 56px;
  padding: 16px;
  padding-bottom: 32px;
}
@media screen and (min-width: 768px) {
  .page-content {
    margin-top: 77px;
  }
}
