.menu {
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: var(--color-dark);
  top: 0;
  left: 0;
  transform: translateY(calc(-100% + 48px));
}
.menu--active {
  transition: transform 300ms ease;
  transform: translateY(0);
}
.menu-desktop {
  display: none;
}
.menu-mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 100%;
}
.menu-mobile--active {
  justify-content: flex-start;
}
.menu-mobile__content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
}
.menu-mobile__content__hamburger {
  cursor: pointer;
  color: var(--color-light);
  display: flex;
  align-items: center;
}

.menu-mobile--active__content {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.menu-mobile--active__content__header {
  padding: 8px 16px;
  width: 100%;
}
.menu-mobile--active__content__header__close-button {
  cursor: pointer;
}
.menu-mobile--active__content__menu-items {
  height: calc(100% - 64px);
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (min-width: 768px) {
  .menu {
    width: 64px;
    transform: translateY(0);
    display: flex;
    transition: transform 0ms ease;
  }
  .menu-desktop {
    display: block;
  }
  .menu-mobile {
    display: none;
  }
  .menu-desktop__logo-container {
    width: 100%;
    height: 80px;
    padding: 24px 16px 24px 16px;
    display: flex;
  }
  .menu-desktop__navigation-container {
    height: calc(100% - 160px);
    width: 100%;
    padding: 16px;
    overflow-y: auto;
    /* overflow-x: hidden; */
  }
  .menu-desktop__quit-container {
    content: " ";
    width: 100%;
    height: 80px;
    padding: 24px 16px 24px 16px;
    display: flex;
  }
}
