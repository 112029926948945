.base-select-input {
  width: 100%;
}
.base-select-input label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-right: 16px;
  margin-bottom: 8px;
}
.base-select-input select {
  width: 100%;
  padding: 8px;
  font-size: 16px;
}
