.live-pledges-monitor__project-selector {
  margin-top: 8px;
}
.live-pledges-monitor__pledges-list {
  background-color: whitesmoke;
  border-radius: 8px;
  margin-top: 8px;
  padding: 8px 0 8px 8px;
  min-height: 160px;
  height: calc(100vh - 470px);
  margin-bottom: 8px;
  overflow-y: auto;
}
.live-pledges-monitor__pledges-list--no-projects {
  height: calc(100vh - 402px);
}
.live-pledges-monitor__pledges-list__item {
  background-color: var(--color-light);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  border: 2px solid transparent;
}
.live-pledges-monitor__pledges-list__item__top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}
.live-pledges-monitor__pledges-list__item__top__delete:hover {
  transition: all 250ms ease;
  cursor: pointer;
  filter: brightness(1.3);
}
.live-pledges-monitor__pledges-list__item__bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.live-pledges-monitor__pledges-list__item__bottom span:first-of-type {
  color: gray;
  font-style: italic;
}
.live-pledges-monitor__pledges-list__item__bottom span:last-of-type {
  color: #2ca795;
  font-weight: bold;
}
.live-pledges-monitor__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.live-pledges-monitor__actions__amounts-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 8px;
}
.live-pledges-monitor__actions__free-amount {
  margin-left: 8px;
  width: 100%;
}

.live-pledges-monitor__actions__free-amount button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
