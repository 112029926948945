.base-notification {
  position: fixed;
  bottom: -80px;
  right: 0;
  width: 100%;
  max-width: 400px;
  height: 80px;
  /* background-color: red; */
  padding: 8px;
  transition: transform 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}
.base-notification--is-active {
  transform: translate3d(0px, -80px, 0);
}

.base-notification__notification {
  height: 100%;
  width: 100%;
  border: 1px solid rgba(225, 225, 225, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.base-notification__notification__content {
  width: 100%;
  font-weight: bold;
  padding: 16px;
}
.base-notification--success .base-notification__notification {
  border-color: #2ca795;
}

.base-notification--success .base-notification__notification__content {
  color: #2ca795;
}
.base-notification--error .base-notification__notification {
  border-color: #ce193d;
}

.base-notification--error .base-notification__notification__content {
  color: #ce193d;
}
