.edit-show-medias-page__grid {
  display: grid;
  width: 100%;
  max-width: 1248px;
  grid-gap: 16px;

  grid-template-areas:
    "cover-selector"
    "logo-selector"
    "stream-configurator";
  margin: auto;
}
#cover-selector {
  grid-area: cover-selector;
}
#logo-selector {
  grid-area: logo-selector;
}
#stream-configurator {
  grid-area: stream-configurator;
}
@media screen and (min-width: 1024px) {
  .edit-show-medias-page__grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "cover-selector stream-configurator"
      "logo-selector stream-configurator";
  }
}
