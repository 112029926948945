.pledges-import-module__form__toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.pledges-import-module__form__module {
  margin-top: 16px;
}
.pledges-import-module__form__module__counter {
  border: 1px solid rgb(200, 200, 200);
  border-radius: 8px;
  height: 232px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pledges-import-module__form__module__counter span:first-of-type {
  font-size: 64px;
  font-weight: bold;
}
.pledges-import-module__form__module__counter span:last-of-type {
  font-size: 20px;
  font-weight: bold;
  margin-top: 16px;
}
