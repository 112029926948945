.edit-show-registrations-page__grid {
  display: grid;
  width: 100%;
  max-width: 1248px;
  grid-gap: 16px;
  grid-template-areas:
    "registrations-preset-selector"
    "registrations-custom-configurator";
  margin: auto;
}
#registrations-preset-selector {
  grid-area: registrations-preset-selector;
}
#registrations-custom-configurator {
  grid-area: registrations-custom-configurator;
}
@media screen and (min-width: 1024px) {
  .edit-show-registrations-page__grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "registrations-preset-selector registrations-custom-configurator";
  }
}
