.edit-show-infos-page__grid {
  display: grid;
  width: 100%;
  max-width: 1248px;
  grid-gap: 16px;
  grid-template-areas:
    "published-selector"
    "on-place-selector"
    "on-remote-selector"
    "show-infos-form"
    "show-color-pledge-form"
    "is-lnbc-selector"
    "delete-show-action";
  margin: auto;
}

#published-selector {
  grid-area: published-selector;
}
#on-place-selector {
  grid-area: on-place-selector;
}
#on-remote-selector {
  grid-area: on-remote-selector;
}
#show-infos-form {
  grid-area: show-infos-form;
}
#delete-show-action {
  grid-area: delete-show-action;
}
#is-lnbc-selector {
  grid-area: is-lnbc-selector;
}
#show-color-pledge-form {
  grid-area: show-color-pledge-form;
}
#preview-counter {
  grid-area: preview-counter;
}
.form-field {
  width: 100%;
  height: 72px;
  background-color: cornflowerblue;
}
@media screen and (min-width: 768px) {
  .edit-show-infos-page__grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "published-selector published-selector"
      "on-place-selector  on-remote-selector"
      "show-infos-form show-infos-form"
      "show-color-pledge-form show-color-pledge-form"
      "preview-counter preview-counter"
      "delete-show-action delete-show-action";
  }
}
