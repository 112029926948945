.stream-configurator__form__stream-activator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
}
.stream-configurator__form__stream-visualizer {
  margin-top: 16px;
}
.stream-configurator__form__stream-visualizer__visualizer {
  margin-top: 16px;
  width: 100%;
  height: 323px;
  background-color: black;
  border-radius: 4px;
  overflow: hidden;
}
